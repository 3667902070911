import React, { Component, useRef } from "react";
import ParticlesBg from 'particles-bg'
import MouseParticles from 'react-mouse-particles'
import "./urlWriterComp.css"
import 'reactjs-popup/dist/index.css';
import Giphy from 'react-hooks-giphy'

class UrlWriter extends Component {
    _createLink() {
        this.setState({finalLink: "http://r.chouic.com/redir.php?appurl=com.amazon.mobile.shopping.web" + encodeURI(this.state.longLink).replace("https://", "%3A%2F%2F") + "&store=" + encodeURI(this.state.shortLink)});
    }

    constructor(props) {
        super(props);
        this.state = {
            shortLink: "",
            longLink: "",
            finalLink: "",
        };

        this.handleChangeShortLink = this.handleChangeShortLink.bind(this);
        this.handleChangeLongLink = this.handleChangeLongLink.bind(this);
        this._createLink = this._createLink.bind(this);
        // this.copyLink = this.copyLink(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeShortLink(event) {
        this.setState({shortLink: event.target.value});
        // this.changeFinalLink()
    }

    handleChangeLongLink(event) {
        this.setState({longLink: event.target.value});
        // this.changeFinalLink()
    }

    handleKeyDownLong = (e) => {
        if (e.key === 'Enter') {
            this._createLink();
        }
    }

    handleKeyDownShort = (e) => {
        if (e.key === 'Enter') {
            this.secondTextInput.focus();
        }
    }

    copy_link = () => {
        var copyText = document.getElementById("finalLink");

        /* Select the text field */
        copyText.select(); 
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
    }
    

    render() {
    return (
        <div>
            <div className={'title_div'}>
                <span style={{marginLeft: 15, fontSize: 24, fontWeight: 'bold'}}>Outil de création de deeplinks Amazon</span>
            </div>
            <div className={'input_div'}>
                <span style={{marginTop: 15, fontSize: 20}}>Lien court</span>
                <input className={"input"} autoComplete="off" type="text" name="shortLink" placeholder="Entre le lien court 😎" returnKeyType="next" onKeyDown={this.handleKeyDownShort} blurOnSubmit={false} onChange={this.handleChangeShortLink} />
                <span style={{marginTop: 15, fontSize: 20}}>Lien long</span>
                <input 
                    autoComplete="off" 
                    ref={(input) => { this.secondTextInput = input; }}
                    className={"input"}
                    type="text" 
                    name="longLink" 
                    onKeyDown={this.handleKeyDownLong} 
                    placeholder="Entre le lien long 🥵" 
                    onChange={this.handleChangeLongLink} 
                />
                <button className={'button_create'} onClick={this._createLink}>
                    <span style={{fontSize: 15}}>Création du lien</span>
                </button>
                <hr className={'horizontal_line'}/>
                <span style={{marginTop: 15, fontSize: 20}}>Lien final</span>
                <input className={"input"} type="text" ref="final" id="finalLink" value={this.state.finalLink} />
                <button className={'button_create'} onClick={this.copy_link}>
                    <span style={{fontSize: 15}}>Copier le lien</span>
                </button>
                <Giphy tag="random"/>
            </div>
            <ParticlesBg color="#000000" type="cobweb" bg={true} />
            <MouseParticles g={1} color="red" num={5} radius={5} cull="col,image-wrapper"/>
        </div>
        );
    }
}

export default UrlWriter;