import React from 'react';
import logo from './logo.svg';
import './App.css';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import AddressForm from "./AddressForm";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import UrlWriter from "./urlWriterComp.js";

export type COUCOU = "P" | "OX" | "OH" | "O" | "OF"


function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
    ></Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const SwitchAll = () => {
  console.log("yes")
  return(
    <Switch>
      <Route exact path="/" component={FormPage}/>
      <Route exact path="/deeplink" component={UrlWriter}/>
    </Switch>
  );
}

const FormPage = () => {
  const classes = useStyles();


  return (
    <Paper className={classes.paper}>
        <React.Fragment>
          <Typography variant="h5" gutterBottom></Typography>
          <Typography variant="subtitle1">
            <br />
          </Typography>
        </React.Fragment>
        <React.Fragment>{getStepContent()}</React.Fragment>
    </Paper>
  );
}
function getStepContent() {
  return <AddressForm />;
}

function App() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Router>
      <React.Fragment>
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              Chouic
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <SwitchAll/>
          <Copyright />
        </main>

      </React.Fragment>
    </Router>
)
}

export default App;
