import TellerGuesser from "chouic-players/TellerGuesser"
import CashManager from "./CashManager"
import TimerManager from "./TimerManager"
import RussianNounsManager from "./RussianNounsManager"
import { Genitalia } from "chouic-constants/Genitalia"
import { Pronoun } from "chouic-constants/Pronoun"

export type Tokens = "P" | "OX" | "OX2" | "OH" | "OH2" | "O" | "O2" | "OF" | "OF2" | "T" | "G"

export interface AssociatedSexeToTokenType {
  [key: string | Tokens]: {
    pronoun: Pronoun
    name: string
    genitalia?: Genitalia
  }
}

export default class BracketMatch {
  private _dareText: string
  private _isMultiPlayerParty: boolean
  private _associatedSexesToTokens: AssociatedSexeToTokenType
  private _isDebug: boolean
  private _isDemo: boolean
  private _isCoupleParty: boolean
  private _numberPlayersInGame: number
  private _debugID: number = 0
  private _timer: number = 30
  private _isTeller: TellerGuesser

  public constructor(
    dareText: string,
    isMultiPlayerParty: boolean,
    associatedSexesToTokens: AssociatedSexeToTokenType,
    isDebug: boolean,
    isDemo: boolean,
    isCoupleParty: boolean,
    numberPlayersInGame: number,
    timer: number,
    isTeller: TellerGuesser = TellerGuesser.Teller
  ) {
    this._dareText = dareText
    this._isMultiPlayerParty = isMultiPlayerParty
    this._associatedSexesToTokens = associatedSexesToTokens
    this._isDebug = isDebug
    this._isDemo = isDemo
    this._isCoupleParty = isCoupleParty
    this._numberPlayersInGame = numberPlayersInGame
    this._timer = timer ? timer : 30
    this._isTeller = isTeller
  }

  public makeWork() {
    if (!this._dareText || this._dareText.length === 0) {
      return ""
    }
    this._cashBracket(/{(\w*)#([0-9]+)}/g)

    // On peut largement améliorer cette partie https://stackoverflow.com/questions/7317043/regex-not-operator
    this._matchBrackets(
      /{(i:P|OX|P|OX2|OH|OH2|RuD|O|O2|OF|OF2|T|G|Genital:OX|Genital:OX2|Genital:P|Genital:O|Genital:O2)#(^$|[^}]*)\*(^$|[^}]*)\*(^$|[^}]*)}/g
    )

    this._matchBrackets(
      /{(i:P|OX|P|OX2|OH|OH2|RuD|O|O2|OF|OF2|T|G|Genital:OX|Genital:OX2|Genital:P|Genital:O|Genital:O2)#(^$|.+?)\*(^$|.+?)}/g
    )

    /*
     ** Search for all remaining tokens
     */
    this._matchBrackets(/{(\w*)#(^$|.+?)\*(^$|.+?)}/g)

    this._timersBracket()

    return this._dareText
  }

  private _matchBrackets(regex: RegExp) {
    const match = regex.exec(this._dareText)
    // console.log("match : ", match)
    if (match) {
      try {
        const replacement = this._applyParsing(match[0], match[1], match[2], match[3], match[4])
        this._replaceBracketsByContent(match[0], replacement as string)
      } catch (error) {
        console.log("🔴🔴🔴  Error: ", error)
        this._replaceBracketsByContent(match[0], "[ERROR: " + error + "]")
      }

      regex.lastIndex = 0
      this._matchBrackets(regex)
    }
  }

  private _applyParsing(
    fullMatch: string,
    token: string,
    firstPart: string,
    secondPart: string,
    thirdPart: string
  ) {
    // console.log("First part : ", firstPart, "second part : ", secondPart)
    if (firstPart !== undefined && secondPart !== undefined) {
      switch (token) {
        case "RuD":
          try {
            return this._russianBracket(firstPart, secondPart)
          } catch (error) {
            throw error
          }
          break

        case "Couple":
        case "couple":
          return this._coupleBracket(firstPart, secondPart)
          break

        case "2Players":
        case "2players":
          return this._2playersBracket(firstPart, secondPart)
          break

        case "Multi":
        case "multi":
          return this._multiplayerbracket(firstPart, secondPart)
          break

        case "TG":
          return this._tellerGuesserBracket(firstPart, secondPart)
          break

        case "P":
        case "OX":
        case "OX2":
        case "O":
        case "O2":
        case "OH":
        case "OH2":
        case "OF":
        case "OF2":
        case "T":

        case "G":
          return this._playerBracket(token, firstPart, secondPart, thirdPart)
          break

        case "Genital:OX":
        case "Genital:OX2":
        case "Genital:P":
        case "Genital:O":
        case "Genital:O2":
          return this._genitalBracket(token, firstPart, secondPart)
          break

        default:
          throw "unknow token"
          break
      }
    } else {
      throw "empty parsing"
    }
  }

  private _russianBracket(token: string, declinaison: string) {
    const player = this._associatedSexesToTokens[token]
    let russianManager = new RussianNounsManager(declinaison, player.name, player.pronoun)
    const declinedName = russianManager.declineNames()
    return declinedName
  }

  private _multiplayerbracket(multiPlayerText: string, twoPlayertext: string) {
    if (this._isMultiPlayerParty) {
      return multiPlayerText
    } else {
      return twoPlayertext
    }
  }

  private _coupleBracket(coupleText: string, multiText: string) {
    if (this._isCoupleParty) {
      return coupleText
    } else {
      return multiText
    }
  }

  private _2playersBracket(twoPlayersText: string, morePlayersText: string): string {
    if (this._numberPlayersInGame == 2) {
      return twoPlayersText
    } else {
      return morePlayersText
    }
  }

  private _tellerGuesserBracket(tellerText: string, guesserText: string): string {
    if (this._isTeller == TellerGuesser.Teller) {
      return tellerText
    } else return guesserText
  }

  private _playerBracket(token: string, manText: string, womenText: string, neutralText: string) {
    if (this._associatedSexesToTokens[token] === null) {
      console.log("🔴🔴🔴  Error: Player not present", token)
    } else if (this._associatedSexesToTokens[token].pronoun == Pronoun.MALE) {
      return manText
    } else if (this._associatedSexesToTokens[token].pronoun == Pronoun.NEUTRAL) {
      return neutralText ?? manText // Languages that does not handle neutralPronon cause issue: fallback in lovbirdz
    } else {
      return womenText
    }
  }

  private _genitalBracket(token: string, malText: string, femalText: string): string {
    const parts = token.split(":")
    const token_part = parts[1] as Tokens // Get "OX2"
    if (this._associatedSexesToTokens[token_part] === null) {
      console.log("🔴🔴🔴  Error: Player not present", token)
    } else if (this._associatedSexesToTokens[token_part].genitalia == Genitalia.MALE) {
      return malText
    } else if (this._associatedSexesToTokens[token_part].genitalia == Genitalia.FEMALE) {
      return femalText
    }
    return ""
  }

  private _cash(amount: number) {
    let cashManager = new CashManager(amount)
    return cashManager.makeWork()
  }

  private _replaceBracketsByContent(bracketMatch: string, replacement: string) {
    // Gestion empty string
    if (replacement == " ") {
      replacement = ""
    }
    if (this._isDebug) {
      replacement = "<part" + this._debugID + ">" + replacement + "</part" + this._debugID + ">"
    }
    if (this._isDemo) {
      replacement = "<part" + this._debugID + ">" + bracketMatch + "</part" + this._debugID + ">"
    }
    this._debugID++

    if (this._isDemo) {
      replacement = replacement.replace("{", "❴")
      replacement = replacement.replace("}", "❵")
    }

    this._dareText = this._dareText.replace(bracketMatch, replacement).replace(/  +/g, " ")
  }

  private _cashBracket(regex: RegExp) {
    const match = regex.exec(this._dareText)
    if (match) {
      try {
        const replacement = this._cash(parseInt(match[2]))
        this._replaceBracketsByContent(match[0], replacement)
      } catch (error) {
        this._replaceBracketsByContent(match[0], "[ERROR: " + error + "]")
      }
      regex.lastIndex = 0
      this._cashBracket(regex)
    }
  }

  private _timersBracket() {
    if (this._dareText.includes("{Timer}") || this._dareText.includes("{timer}")) {
      let timerManager = new TimerManager(this._timer, this._isDebug, this._isDemo)
      let timerReplacement = timerManager.makeWork()
      this._dareText = this._dareText.replace(/{Timer}/g, timerReplacement)
      this._dareText = this._dareText.replace(/{timer}/g, timerReplacement)
    }
  }
}
