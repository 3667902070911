import React, { createRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { FieldValues, useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import BracketMatch, { Tokens } from "chouic-dares/BracketMatch";
import Typography from "@material-ui/core/Typography";
import { Genitalia } from "chouic-constants/Genitalia";
import { Pronoun } from "chouic-constants/Pronoun";
import TellerGuesser from "chouic-players/TellerGuesser";

type WatchFields = {
  dare: string;
  nombreDeJoueurs: string;
  isCoupleParty: string;
  rtl: string;
  fakeNames: string;
  sideTG: string;
  sexedP: Pronoun;
  sexedO: Pronoun;
  sexedO2: Pronoun;
  sexedOX: Pronoun;
  sexedOX2: Pronoun;
  sexedOH: Pronoun;
  sexedOF: Pronoun;
  sexedT: Pronoun;
  sexedG: Pronoun;
  genitalP: Genitalia;
  genitalO: Genitalia;
  genitalO2: Genitalia;
  genitalOX: Genitalia;
  genitalOX2: Genitalia;
  genitalOH: Genitalia;
  genitalOF: Genitalia;
  genitalT: Genitalia;
  genitalG: Genitalia;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "90%",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
    "& .MuiInputBase-input": {
      height: 170,
      fontSize: 30,
      lineHeight: "45px",
    },
    "& timer": {
      background: "green",
      color: "#fff",
    },
    "& part0": {
      background: "yellow",
      display: "inline-block",
    },
    "& part1": {
      textDecoration: "underline",
      display: "inline-block",
    },
    "& part2": {
      fontWeight: "bold",
    },

    "& part3": {
      background: "blue",
      color: "#fff",
      display: "inline-block",
    },

    "& part4": {
      "text-decoration-line": "overline underline",
      "text-decoration-style": "wavy",
      display: "inline-block",
    },

    "& part5": {
      background: "grey",
      color: "#fff",
      display: "inline-block",
    },

    "& name": {
      background: "#f6e58d",
      color: "#000",
      display: "inline-block",
    },
  },
}));

const disableCoupleOrFriendChoice = (
  nbPlayers: string,
  watchAllFields: any,
  register: any
) => {
  if (nbPlayers == "1") {
    return (
      <select disabled style={{ backgroundColor: "#c9c9c9" }} {...register}>
        <option value="0">En couple</option>
        <option selected value="1">
          Entre amis
        </option>
      </select>
    );
  } else {
    if (watchAllFields.isCoupleParty == 0) {
      return (
        <select {...register}>
          <option selected value="0">
            En couple
          </option>
          <option value="1">Entre amis</option>
        </select>
      );
    } else {
      return (
        <select {...register}>
          <option value="0">En couple</option>
          <option selected value="1">
            Entre amis
          </option>
        </select>
      );
    }
  }
};

function createBracketMatcher(watchAllFields: WatchFields, withDemo: boolean) {
  const namesByPronoun = {
    [Pronoun.FEMALE]: {
      P: "Мария",
      OX: "Анна",
      OX2: "Мария",
      O: "Юлия",
      O2: "Анна",
      T: "Анна",
      G: "Анна",
    },
    [Pronoun.MALE]: {
      P: "Максим",
      OX: "Жан",
      OX2: "Квентин",
      O: "Квентин",
      O2: "Поль",
      T: "Поль",
      G: "Поль",
    },
    [Pronoun.NEUTRAL]: {
      P: "Максим",
      OX: "Жан",
      OX2: "Квентин",
      O: "Квентин",
      O2: "Поль",
      T: "Поль",
      G: "Поль",
    },
  };

  return new BracketMatch(
    watchAllFields.dare,
    watchAllFields.nombreDeJoueurs !== "0",
    {
      P: {
        name: namesByPronoun[watchAllFields.sexedP as Pronoun.FEMALE].P,
        pronoun: watchAllFields.sexedP,
        genitalia: watchAllFields.genitalP,
      },
      OX: {
        name: namesByPronoun[watchAllFields.sexedOX as Pronoun.FEMALE].OX,
        pronoun: watchAllFields.sexedOX,
        genitalia: watchAllFields.genitalOX,
      },
      OX2: {
        name: namesByPronoun[watchAllFields.sexedOX2 as Pronoun.FEMALE].OX2,
        pronoun: watchAllFields.sexedOX2,
        genitalia: watchAllFields.genitalOX2,
      },
      O: {
        name: namesByPronoun[watchAllFields.sexedO as Pronoun.FEMALE].O,
        pronoun: watchAllFields.sexedO,
        genitalia: watchAllFields.genitalO,
      },
      O2: {
        name: namesByPronoun[watchAllFields.sexedO2 as Pronoun.FEMALE].O2,
        pronoun: watchAllFields.sexedO2,
        genitalia: watchAllFields.genitalO2,
      },
      OF: {
        name: "Юлия",
        pronoun: Pronoun.FEMALE,
      },
      OF2: {
        name: "Мария",
        pronoun: Pronoun.FEMALE,
      },
      OH: { pronoun: Pronoun.MALE, name: "Пьер" },
      OH2: { pronoun: Pronoun.MALE, name: "Поль" },
      T: {
        name: namesByPronoun[watchAllFields.sexedT as Pronoun.FEMALE].T,
        pronoun: watchAllFields.sexedT,
        genitalia: watchAllFields.genitalT,
      },
      G: {
        name: namesByPronoun[watchAllFields.sexedG as Pronoun.FEMALE].G,
        pronoun: watchAllFields.sexedG,
        genitalia: watchAllFields.genitalG,
      },
    },
    true,
    withDemo,
    watchAllFields.isCoupleParty === "0" &&
      watchAllFields.nombreDeJoueurs === "0",
    watchAllFields.nombreDeJoueurs === "0" ? 2 : 3,
    30,
    watchAllFields.sideTG === TellerGuesser.Teller
      ? TellerGuesser.Teller
      : TellerGuesser.Guesser
  );
}
function prepareBracketMatchText(bracketMatcher: any) {
  let text = bracketMatcher.makeWork();
  return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
}

const Form = ({}) => {
  const [renderData, setRenderData] = useState("");
  const classes = useStyles();
  const { handleSubmit, control, watch, register } = useForm();
  const onSubmit = (data: any) => {
    console.log(data);
  };
  const watchAllFields = watch() as WatchFields; // when pass nothing as argument, you are watching everything
  // TODO : Modfiier родительный par les prénoms fournis par la traductrice (En prenant en compte le sexe)

  let torenderHighlitedDemo = "";
  let torenderHighlited = "";

  if (watchAllFields.sexedP) {
    let bracketMatcher = createBracketMatcher(watchAllFields, false);
    torenderHighlited = prepareBracketMatchText(bracketMatcher);

    let bracketMatcherDemo = createBracketMatcher(watchAllFields, true);
    torenderHighlitedDemo = prepareBracketMatchText(bracketMatcherDemo);
  }
  // Plus tard dans votre composant, vous pouvez utiliser ces fonctions :

  if (watchAllFields.fakeNames == "1") {
    if (watchAllFields.sexedP == Pronoun.FEMALE) {
      torenderHighlited = torenderHighlited.replace(
        /%P/g,
        "<name>Émilie</name>"
      );
    } else {
      torenderHighlited = torenderHighlited.replace(
        /%P/g,
        "<name>Maxime</name>"
      );
    }
    if (watchAllFields.sexedOX == Pronoun.FEMALE) {
      torenderHighlited = torenderHighlited.replace(
        /%OX2/g,
        "<name>Rachelle</name>"
      );
      torenderHighlited = torenderHighlited.replace(/%OX/g, "<name>Léa</name>");
    } else {
      torenderHighlited = torenderHighlited.replace(
        /%OX2/g,
        "<name>Léo</name>"
      );
      torenderHighlited = torenderHighlited.replace(
        /%OX/g,
        "<name>Anthony</name>"
      );
    }

    if (watchAllFields.sexedT == Pronoun.FEMALE) {
      torenderHighlited = torenderHighlited.replace(
        /%T/g,
        "<name>Uliette</name>"
      );
    } else {
      torenderHighlited = torenderHighlited.replace(
        /%T/g,
        "<name>Ulios</name>"
      );
    }

    if (watchAllFields.sexedG == Pronoun.FEMALE) {
      torenderHighlited = torenderHighlited.replace(/%G/g, "<name>Irma</name>");
    } else {
      torenderHighlited = torenderHighlited.replace(
        /%G/g,
        "<name>Didier</name>"
      );
    }

    torenderHighlited = torenderHighlited.replace(
      /%OH2/g,
      "<name>Pierre</name>"
    );
    torenderHighlited = torenderHighlited.replace(/%OH/g, "<name>Jean</name>");
    torenderHighlited = torenderHighlited.replace(
      /%OF2/g,
      "<name>Lucie</name>"
    );
    torenderHighlited = torenderHighlited.replace(/%OF/g, "<name>Marie</name>");

    if (watchAllFields.sexedO == Pronoun.FEMALE) {
      torenderHighlited = torenderHighlited.replace(
        /%O2/g,
        "<name>Anne</name>"
      );
    } else {
      torenderHighlited = torenderHighlited.replace(
        /%O2/g,
        "<name>Quentin</name>"
      );
    }
    if (watchAllFields.sexedO == Pronoun.FEMALE) {
      torenderHighlited = torenderHighlited.replace(
        /%O/g,
        "<name>Julie</name>"
      );
    } else {
      torenderHighlited = torenderHighlited.replace(/%O/g, "<name>Paul</name>");
    }
  }

  let have_error = false;
  let text_error = "";

  if (torenderHighlited.indexOf("% ") != -1) {
    have_error = true;
    text_error +=
      torenderHighlited.substring(
        torenderHighlited.indexOf("%") - 15,
        torenderHighlited.indexOf("%") + 15
      ) + "<br>";
  }
  if (torenderHighlited.indexOf("{") != -1) {
    have_error = true;
    text_error +=
      torenderHighlited.substring(
        torenderHighlited.indexOf("{") - 15,
        torenderHighlited.indexOf("{") + 15
      ) + "<br>";
  }
  if (torenderHighlited.indexOf("}") != -1) {
    have_error = true;
    text_error +=
      torenderHighlited.substring(
        torenderHighlited.indexOf("}") - 15,
        torenderHighlited.indexOf("}") + 15
      ) + "<br>";
  }
  if (torenderHighlited.indexOf("*") != -1) {
    have_error = true;
    text_error +=
      torenderHighlited.substring(
        torenderHighlited.indexOf("*") - 15,
        torenderHighlited.indexOf("*") + 15
      ) + "<br>";
  }
  if (torenderHighlited.indexOf("ERROR") != -1) {
    have_error = true;
    text_error +=
      torenderHighlited.substring(
        torenderHighlited.indexOf("ERROR") - 10,
        torenderHighlited.indexOf("[ERROR]") + 10
      ) + "<br>";
  }
  // React.useLayoutEffect(() => {

  document.body.setAttribute("dir", watchAllFields.rtl === "1" ? "rtl" : "ltr");
  // }, [watchAllFields.rtl]);

  const renderPlayer = (player: string) => {
    return (
      <>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              paddingTop: 40,
              marginRight: 20,
            }}
          >
            <Typography component="h4" variant="h4" align="center">
              %{player}
            </Typography>
          </div>

          <div
            style={{
              margin: 10,
            }}
          >
            <p style={{ textAlign: "center" }}>
              <b>Pronom de %{player}</b>
            </p>
            <select {...register("sexed" + player)}>
              <option value={Pronoun.MALE}>Homme</option>
              <option value={Pronoun.FEMALE}>Femme</option>
              <option value={Pronoun.NEUTRAL}>Neutre</option>
            </select>
          </div>
          <div
            style={{
              margin: 10,
            }}
          >
            <p style={{ textAlign: "center" }}>
              <b>Genital de %{player}</b>
            </p>
            <select {...register("genital" + player)}>
              <option value={Genitalia.MALE}>Masculin</option>
              <option value={Genitalia.FEMALE}>Feminin</option>
            </select>
          </div>
        </div>
      </>
    );
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Gage à tester"
        multiline
        rows={4}
        {...register("dare")}
        placeholder="Entrez votre gage"
      />
      {have_error ? (
        <Typography
          style={{ padding: 30, backgroundColor: "red" }}
          component="h1"
          variant="h4"
          align="center"
        >
          <p style={{ color: "#fff" }}>
            ⚠️ Le gage contient au moins les erreurs suivantes{" "}
          </p>
          <div
            style={{ color: "#fff" }}
            dangerouslySetInnerHTML={{ __html: text_error }}
          />
        </Typography>
      ) : (
        false
      )}
      <br />
      <Typography
        style={{ padding: 30, fontSize: 18 }}
        component="h2"
        variant="h4"
        align="center"
      >
        <div dangerouslySetInnerHTML={{ __html: torenderHighlitedDemo }} />
      </Typography>
      <Typography component="h1" variant="h4" align="center">
        Le gage s'affichera
      </Typography>
      <Typography
        style={{ padding: 30, fontSize: 18 }}
        component="h2"
        variant="h4"
        align="center"
      >
        <div dangerouslySetInnerHTML={{ __html: torenderHighlited }} />
      </Typography>
      <br />
      <br />
      <Typography component="h1" variant="h4" align="center">
        Configurer les options du gage
      </Typography>
      <p style={{ textAlign: "center" }}>
        <b>Nombre de joueurs</b>
      </p>
      <select {...register("nombreDeJoueurs")}>
        <option value="0">2 joueurs</option>
        <option value="1">Plus de 2 joueurs</option>
      </select>
      <br />
      (Affiche la version du gage en mode couple, ou dans une partie entre amis)
      <br />
      {disableCoupleOrFriendChoice(
        watchAllFields.nombreDeJoueurs,
        watchAllFields,
        register("isCoupleParty")
      )}
      <br />
      {renderPlayer("P")}
      {renderPlayer("O")}
      {renderPlayer("O2")}
      {renderPlayer("OX")}
      {renderPlayer("OX2")}
      {renderPlayer("OH")}
      {renderPlayer("OF")}
      {renderPlayer("T")}
      {renderPlayer("G")}
      <p>
        <b>Côté de %TG</b>
      </p>
      <select {...register("sideTG")}>
        <option value={TellerGuesser.Teller}>Teller</option>
        <option value={TellerGuesser.Guesser}>Guesser</option>
      </select>
      <p>
        <b>Ajouter de faux prénoms</b>
      </p>
      <select {...register("fakeNames")}>
        <option value="1">Oui</option>
        <option value="0">Non</option>
      </select>
      <p>
        <b>RTL</b>
      </p>
      <select {...register("rtl")}>
        <option value="0">Non</option>
        <option value="1">Oui</option>
      </select>
    </form>
  );
};

export default Form;
