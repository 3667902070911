export default class TimerManager {
  private _duration: number
  private _isDebug: boolean
  private _isDemo: boolean

  public constructor(duration: number, isDebug: boolean, isDemo: boolean) {
    this._duration = duration
    this._isDebug = isDebug
    this._isDemo = isDemo
  }

  public makeWork() {
    let timerReplacement = ""
    if (this._isDebug) {
      timerReplacement = "<timer>" + this._duration + "\u00a0secondes" + "</timer>"
    }
    if (this._isDemo) {
      timerReplacement = "<timer>" + "{Timer}" + "</timer>"
    }
    return timerReplacement
  }
}
