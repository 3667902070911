import RussianNouns from "./RussianNoun"
import { Pronoun } from "chouic-constants/Pronoun"

const Gender = RussianNouns.Gender
const Case = RussianNouns.Case

export default class RussianNounsManager {
  private _declinaison: string
  private _name: string
  private _sexe: string

  public constructor(declinaison: string, name: string, pronoun: Pronoun) {
    this._name = name
    this._sexe = pronoun == Pronoun.FEMALE ? Gender.FEMININE : Gender.MASCULINE

    // Problème Avec l'accusatif masculin https://chouic.slack.com/archives/C0KKT3MM0/p1632756928042700
    if (this._sexe == Gender.MASCULINE && declinaison == "A") {
      this._declinaison = Case.GENITIVE
      return
    }

    switch (declinaison) {
      case "N":
        this._declinaison = Case.NOMINATIVE
        break
      case "G":
        this._declinaison = Case.GENITIVE
        break
      case "D":
        this._declinaison = Case.DATIVE
        break
      case "A":
        this._declinaison = Case.ACCUSATIVE
        break
      case "I":
        this._declinaison = Case.INSTRUMENTAL
        break
      case "P":
        this._declinaison = Case.PREPOSITIONAL
        break
      default:
        throw "Unexisting declinaison: " + declinaison
        break
    }
  }

  public declineNames() {
    if (/[а-яА-ЯЁё]/.test(this._name)) {
      const rne = new RussianNouns.Engine()

      let coat = RussianNouns.createLemma({
        text: this._name,
        gender: this._sexe,
      })

      const declinedName = rne.decline(coat, this._declinaison)[0]
      return "<p>" + declinedName + "</p>"
    } else {
      console.log(this._name, "is not cyrillic")
      return "<p>" + this._name + "</p>"
    }
  }
}
