/*
This class is only here to have typing on web apps. 
See CashManager.native.ts for in app behaviour. 
*/

export default class CashManager {
  private amount: number
  public constructor(amount: number) {
    this.amount = amount
  }

  public makeWork(): string {
    return this.amount.toString()
  }
}
